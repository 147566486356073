import React from 'react';
import {questions} from "../../store";
import {observer} from "mobx-react-lite";
import './explanation.sass';
import {PulseLoader} from "react-spinners";

const Explanation = () => {
    return (
        questions.loading ?
            <div className='loader'>
                <PulseLoader color='#DAFA7D'/>
            </div>
            :
            <div className='explanation'>
                <div className="explanation__left">
                    <div className='quiz-questions__number'>
                        <h2 className='quiz-questions__number-header'>Правильный ответ</h2>
                    </div>

                    <div className='quiz-questions__answers'>
                        <input
                            className='checkbox'
                            type="checkbox"
                            checked={true}
                        />
                        <span>{questions.explanation.correct_answer}</span>
                    </div>

                    <div className='quiz-questions__text'>
                        {questions.explanation.explanation_text}
                    </div>


                </div>
                <div className="explanation__right">
                    {
                        questions.explanation.explanation_image_url &&
                        <img
                            className='explanation__img'
                            src={`https://викторина.форум.нцпти.рф/api/static/${questions.explanation.explanation_image_url}`}
                            alt="Картинка ответа"
                        />
                    }
                </div>
            </div>

    )
        ;
};

export default observer(Explanation);