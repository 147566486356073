import React from 'react';
import './result.sass'
import {questions} from '../../store';
import {observer} from "mobx-react-lite";
import {Link} from "react-router-dom";
import {PulseLoader} from "react-spinners";

const Results = () => {

    function getScoreText(score) {
        let text = 'балл';
        if (score % 10 === 1 && score % 100 !== 11) {
            text = 'балл';
        } else if (score % 10 >= 2 && score % 10 <= 4 && (score % 100 < 10 || score % 100 >= 20)) {
            text = 'балла';
        } else {
            text = 'баллов';
        }
        return `Вы набрали ${score} ${text}!`;
    }


    return (
        <section className='result'>
            {
                questions.loading ?
                    <div className='loader'>
                        <PulseLoader color='#000000'/>
                    </div>
                    :
                    <article className='result__head'>
                        {
                            questions.score === 10 &&
                            <>
                                < h3 className='result__title'>ВАУ, ВЫ НАБРАЛИ {questions.score} БАЛЛОВ!</h3>
                                <p className='result__text'>Вы Мастер профилактики и нет вам равных!</p>
                            </>
                        }

                        {
                            (questions.score === 8 || questions.score === 9) &&
                            <>
                                <h3 className='result__title'>ВЫ НАБРАЛИ {questions.score} БАЛЛОВ!</h3>
                                <p className='result__text'>А вы хороши, вас сложно провести!</p>
                            </>
                        }

                        {
                            questions.score === 7 &&
                            <>
                                <h3 className='result__title'>ВЫ НАБРАЛИ {questions.score} БАЛЛОВ!</h3>
                                <p className='result__text'>Стоит повторить материал, но это твердая четверка!</p>
                            </>
                        }

                        {
                            (questions.score === 6 || questions.score === 5) &&
                            <>
                                <h3 className='result__title'>ВЫ НАБРАЛИ {questions.score} БАЛЛОВ!</h3>
                                <p className='result__text'>Всегда есть к чему стремиться!</p>
                            </>
                        }

                        {
                            (questions.score > 1 && questions.score <= 4) &&
                            <>
                                <h3 className='result__title'>ВЫ НАБРАЛИ {questions.score} БАЛЛА!</h3>
                                <p className='result__text'>Чтобы стать мастером профилактики, скорее открывайте наши <a
                                    href="https://vk.com/ncpti_rnd" target="_blank">социальные сети</a>, там много профилактических
                                    материалов, которые помогут в работе!</p>
                            </>
                        }
                        {
                            (questions.score == 1) &&
                            <>
                                <h3 className='result__title'>ВЫ НАБРАЛИ {questions.score} БАЛЛ!</h3>
                                <p className='result__text'>Чтобы стать мастером профилактики, скорее открывайте наши <a
                                    href="https://vk.com/ncpti_rnd" target="_blank">социальные сети</a>, там много профилактических
                                    материалов, которые помогут в работе!</p>
                            </>
                        }
                        {
                            (questions.score == 0) &&
                            <>
                                <h3 className='result__title'>ВЫ НАБРАЛИ {questions.score} БАЛЛОВ!</h3>
                                <p className='result__text'>Чтобы стать мастером профилактики, скорее открывайте наши <a
                                    href="https://vk.com/ncpti_rnd" target="_blank">социальные сети</a>, там много профилактических
                                    материалов, которые помогут в работе!</p>
                            </>
                        }
                    </article>
            }
            <Link to='/leaders'>
                <button className='quiz-btn result__btn'>
                    Таблица лидеров
                </button>
            </Link>
        </section>
    );
};

export default observer(Results);