import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import './menu.sass'
import Modal from "../modal/Modal";
import {observer} from "mobx-react-lite";
import {questions, reset, user} from '../../store';
import {baseUrl} from "../../constant";

function Menu() {
    const [nameValid, setNameValid] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    const startTest = () => {
        if ((user.nameTmp.trim() && (user.nameTmp.match(/[^a-zа-я\s-]/i) == null))) {
            console.log(user.nameTmp)
            authorization();
        } else {
            setNameValid(false)
        }
    }

    const authorization = () => {
        fetch(baseUrl + 'user/auth', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                accept: 'application/json',
            },
            body: JSON.stringify({fio: user.nameTmp})
        })
            .then(response => response.json())
            .then(json => {
                if (json.access_token) {
                    reset()
                    sessionStorage.setItem('timeStart', new Date())
                    user.setToken(json.access_token);
                    questions.answers = [];
                    user.saveName();
                    user.setId(json.id);
                    navigate('/quiz')
                }
            })
    }

    const closeModal = (event) => {
        event.stopPropagation()
        setIsModalOpen(false);
    }
    return (
        <main className="menuWrapper">
            <div className="menuContentWrapper">
                <h3>АВТОРИЗАЦИЯ</h3>
                <div className="menuContent">
                    <input
                        className={`inputWhite ${nameValid ? '' : 'invalid'}`} placeholder={'ФИО'}
                        value={user.nameTmp ? user.nameTmp : user.name}
                        onChange={(e) => user.writeName(e.target.value, setNameValid)}
                    >
                    </input>
                    <button onClick={startTest} className="btnWhite ">
                        Начать тестирование
                        {isModalOpen && <Modal closeModal={closeModal} authorization={authorization}/>}
                    </button>
                    <button className="btnWhite menu-btn" onClick={() => navigate('/leaders')}>
                        Таблица лидеров
                    </button>
                </div>
            </div>
        </main>
    )
}

export default observer(Menu);
