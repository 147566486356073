import React from 'react';
import {resetQuestions} from "../../store";
import {Link} from "react-router-dom";
import {MdLogout} from "react-icons/md";

const BackToAuth = () => {
    return (
        <div onClick={() => resetQuestions()}
             className={`content__footer ${window.location.pathname == '/leaders' ? 'hide' : 'show'}`}>
            <Link className="layout__back" to="/"> <MdLogout/>К  авторизации</Link>
        </div>
    );
};

export default BackToAuth;