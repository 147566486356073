import React from 'react';
import {Link, useLocation} from "react-router-dom";
import './layout.sass'
import logo from "../../assets/img/newDesign/logo.png";
import {MdLogout} from "react-icons/md";
import {resetQuestions} from '../../store';
import firstImg from '../../assets/img/newDesign/1.png'
import secondImg from '../../assets/img/newDesign/2.png'
import thirdImg from '../../assets/img/newDesign/3.png'
import fourthImg from '../../assets/img/newDesign/4.png'
import fifthImg from '../../assets/img/newDesign/5.png'
import sixImg from '../../assets/img/newDesign/6.png'

function Layout({ children }) {
    const { search } = useLocation();
    return (
        <div className='backgroundWrapper'>
            <div className='header'>
                <Link to="/"><img src={logo} alt="Логотип"/></Link>
                {/*<h3 className='header__title'>КиберВикторина</h3>*/}
            </div>
            <img src={firstImg} className='first-img back-img' alt="бэкраунд 1"/>
            <img src={secondImg}  className='second-img back-img' alt="бэкраунд 2"/>
            <img src={thirdImg}  className='third-img back-img' alt="бэкраунд 3"/>
            <img src={fourthImg}  className='fourth-img back-img' alt="бэкраунд 4"/>
            <img src={fifthImg}  className='five-img back-img' alt="бэкраунд 5"/>
            <img src={sixImg}  className='six-img back-img' alt="бэкраунд 6"/>

            <div className="contentWrapper">
                <div className={window.location.pathname == '/leaders' ? 'content content-leaders' : 'content'}>
                    <div className="content__inner">
                        { children }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Layout;