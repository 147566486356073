import React, {useEffect} from 'react';
import './leaders.sass'
import {raiting} from '../../store';
import {observer} from "mobx-react-lite";
import first from "../../assets/img/1.svg"
import second from "../../assets/img/2.svg"
import third from "../../assets/img/3.svg"

function Leaders() {
    useEffect(() => {
        raiting.setLeaders()
    }, [])
    return (

        <div className='leaders-container'>
            <h3 className="leaders__header">Рейтинг участников тестирования</h3>
            <div className="leaders-table__wrapper">
                <div className="leaders-table">
                    {
                        raiting.leaders.length &&
                        raiting.leaders.map((row, index) => (
                            <div key={index} className='item'>
                                {
                                    index < 3 ?
                                        <>
                                            <img className='top-img'
                                                 src={index == 0 ? first : index == 1 ? second : third}/>
                                        </>
                                        :
                                        <div className='leaders-table__number'>{index + 1}</div>
                                }
                                <div className='leaders-table__cell cell-fio'>{row.fio}</div>
                                <div className='leaders-table__cell cell-border'></div>
                                <div className='leaders-table__cell'>{row.rating}/10</div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default observer(Leaders);