import {makeAutoObservable} from "mobx"
import {baseUrl} from "./constant";

class User {
    name = ''
    nameTmp = ''
    id = 0
    token = ''

    constructor() {
        makeAutoObservable(this)
    }

    setToken(token) {
        this.token = token
    }

    setId(id) {
        this.id = id
    }

    writeName(value, validate) {
        this.nameTmp = value
        if (this.nameTmp) {
            validate(true)
        } else {
            validate(false)
        }
    }

    saveName() {
        this.name = this.nameTmp
    }

    reset() {
        this.id = 0
        this.name = ''
        this.nameTmp = ''
    }
}

class Questions {
    list = []
    progress = []
    answers = {}
    score = 0
    waiting = true
    currentAnswer = 0
    currentQuestion = 0
    explanation = {}
    loading = false

    constructor() {
        makeAutoObservable(this)
    }

    select(option, i) {
        this.progress[i] = option;
    }

    // получить массив вопросов
    // отправить запрос на ответы
    // перебрать вопросы и соединить их с ответами
    // записать все в лист
    setAnswers(questions) {
        fetch(baseUrl + 'answer/', {
            headers: {
                accept: 'application/json',
            },
        }).then((response) => {
            response.json().then((result) => {
                result.map((answer) => {
                    this.list.map((question, i) => {
                        if (answer.question_id == question.id) {
                            if (this.list[i].options) {
                                this.list[i].options.push({
                                    text: answer.text,
                                    is_correct: answer.is_correct,
                                    question_id: answer.question_id,
                                    id: answer.id
                                })
                            } else {
                                this.list[i].options = [{
                                    text: answer.text,
                                    is_correct: answer.is_correct,
                                    question_id: answer.question_id,
                                    id: answer.id
                                }]
                            }
                        }
                    })
                })
            })
        })
    }

    get() {
        fetch(baseUrl + 'question/', {
            headers: {
                accept: 'application/json',
            },
        }).then((response) => {
            response.json().then((result) => {
                this.list = result
                this.setAnswers(result)
            })
        })
    }

    async selectAnswer(question, answer, correct) {

        this.progress[question] = correct
        this.answers[question] = answer
        this.currentAnswer = answer
        this.currentQuestion = question

    }

    async submit() {
        try {
            this.loading = true;
            await fetch(baseUrl + 'choice/', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    accept: 'application/json',
                    Authorization: `Bearer ${user.token}`
                },
                body: JSON.stringify({
                    question_id: +this.currentQuestion,
                    answer_id: +this.currentAnswer
                })
            })
                .then(res => res.json())
                .then(data => this.explanation = data)
        } catch (e) {
            console.log(e)
        } finally {
            this.loading = false;
        }
    }

    async finish() {
        try {
            await fetch(baseUrl + `user/${user.id}/finish`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    accept: 'application/json',
                    Authorization: `Bearer ${user.token}`
                },
                body: JSON.stringify({is_finished: true})
            })
        } catch (e) {
            console.log(e)
        }
    }

    async save() {
        try {
            this.loading = true;
            await fetch(baseUrl + `user/${user.id}`, {
                headers: {
                    Authorization: `Bearer ${user.token}`
                },
            }).then(result => {
                result.json().then(json => {
                    this.score = json.rating
                    this.waiting = false
                })
            })
        } catch (e) {
            console.log(e)
        } finally {
            this.loading = false;
        }
    }

    reset() {
        this.progress = []
        this.score = 0
    }
}


class Raiting {
    leaders = []
    user = {
        rating: 0,
        name: '',
        score: 0,
        time: '00:00'
    }

    constructor() {
        makeAutoObservable(this)
    }

    setLeaders() {
        fetch(baseUrl + 'user/rating', {
            headers: {
                accept: 'application/json',
            },
        }).then((response) => {
            response.json().then((result) => {
                this.leaders = result.sort(function (a, b) {
                    if (b.rating == a.rating) {
                        return a.time < b.time ? -1 : 1
                    } else {
                        return b.rating - a.rating
                    }
                })
                // прокликивание
                // this.leaders = this.leaders.filter((el) => {
                //     const m = el.time.split(':')[0]
                //     const s = el.time.split(':')[1].split('.')[0]
                //     return m != '00' || (+s > 15)
                // })
                //отображение пользователя который зашёл глянуть доску лидеров
                // if (user.id) {
                //     this.leaders.map((el, i) => {
                //         if (el.id == user.id) {
                //             this.user.rating = i
                //             this.user.name = el.fio
                //             this.user.score = el.rating
                //             this.user.time = el.time.split('.')[0]
                //         }
                //     })
                // }
            })
        })
        setTimeout(() => this.setLeaders(), 60000)
    }
}

class Timer {
    minutes = 0
    seconds = 0
    start = new Date().getTime()
    time = 0
    stop = true
    ms = 0

    constructor() {
        makeAutoObservable(this)
        const minutes = sessionStorage.getItem("minutes")
        const seconds = sessionStorage.getItem("seconds")
        if (minutes || seconds) {
            sessionStorage.setItem("minutes", this.minutes);
            sessionStorage.setItem("seconds", this.seconds);
            this.minutes = minutes
            this.seconds = seconds
        }
    }

    reset() {
        this.minutes = 0
        this.seconds = 0
        this.ms = 0
        this.start = new Date().getTime()
        this.time = 0
        this.stop = true
    }

    stopTimer() {
        this.stop = true
    }

    update(start) {
        const time = new Date(new Date() - new Date(sessionStorage.getItem('timeStart')))
        this.minutes = time.getMinutes()
        this.seconds = time.getSeconds()
        this.time += 1000;
        var diff = (new Date().getTime() - this.start) - this.time;
        window.setTimeout(() => this.update(), (1000 - diff));
    }
}

export function reset() {
    user.reset()
    questions.reset()
    timer.reset()
}

export function resetQuestions() {
    questions.reset()
    timer.reset()
}

export const user = new User();
export const questions = new Questions();
export const raiting = new Raiting();
export const timer = new Timer();
