import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import './quiz.sass'
import {questions, user} from '../../store';
import {observer} from "mobx-react-lite";
import BackToAuth from "../backToAuth/backToAuth";
import Explanation from "../explanation/explanation";

function Quiz() {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [showExplanation, setShowExplanation] = useState(false);

    useEffect(() => {
        if (user.token) {
            questions.get()
        } else {
            window.location.pathname = '/'
        }
    }, [])

    const handleNext = () => {
            questions.submit()
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            setShowExplanation(true);
    };

    const handlePrev = () => {
        setCurrentQuestionIndex(currentQuestionIndex - 1);
    };

    return (
        <div className="quiz-wrapper">
            {
                questions.list.length ? (
                    <div className='quiz-questions'>
                        {
                            showExplanation ?
                                <Explanation />
                                :
                                <>
                                    <div className='quiz-questions__number'>
                                        <h2 className='quiz-questions__number-header'>Вопрос {currentQuestionIndex + 1}</h2>
                                    </div>
                                    <div className='explanation question-wrapper'>
                                        <div className="explanation__left">
                                            <div className='quiz-questions__text'
                                                 dangerouslySetInnerHTML={{__html: questions.list[currentQuestionIndex].text}}></div>
                                        </div>
                                        <div className="explanation__right">
                                            {
                                            questions.list[currentQuestionIndex].image_url &&
                                                <img
                                                    className='explanation__img'
                                                    src={`https://викторина.форум.нцпти.рф/api/static/${questions.list[currentQuestionIndex].image_url}`}
                                                    alt="Картинка ответа"
                                                />
                                            }
                                        </div>
                                    </div>
                                    {(() => {
                                        if (questions.list[currentQuestionIndex].options) {
                                            return questions.list[currentQuestionIndex].options.map((answer, index) => {
                                                return <div className='quiz-questions__answers' key={index}>
                                                    <input
                                                        className='checkbox'
                                                        type="checkbox"
                                                        checked={questions.answers[questions.list[currentQuestionIndex].id] == answer.id}
                                                        onClick={() => questions.selectAnswer(questions.list[currentQuestionIndex].id, answer.id)}
                                                    />
                                                    <span
                                                        onClick={() => questions.selectAnswer(questions.list[currentQuestionIndex].id, answer.id, answer.is_correct)}>
                                            {answer.is_correct}
                                                        {answer.text}
                                        </span>
                                                </div>
                                            })
                                        }
                                    })()}
                                </>
                        }
                        <div className='quiz-bottom'>
                            <BackToAuth/>
                            {
                               ( !showExplanation) &&
                                <button className='quiz-btn'
                                        disabled={!questions.answers[questions.list[currentQuestionIndex].id]}
                                        onClick={() => handleNext(questions.answers[questions.list[currentQuestionIndex].id])}>
                                    Следующая страница
                                </button>
                            }

                            {
                                (showExplanation && currentQuestionIndex < questions.list.length) &&
                                <button className='quiz-btn'
                                        onClick={() => setShowExplanation(false)}>
                                    Далее
                                </button>
                            }

                            {(currentQuestionIndex === questions.list.length  && showExplanation) &&
                                <Link to="/result">
                                    <button className='quiz-btn'
                                            onClick={() => {
                                                questions.save();
                                                questions.finish();
                                            }}>
                                        Результаты
                                    </button>
                                </Link>
                            }
                            <div className="count">
                                {currentQuestionIndex}/10
                            </div>
                        </div>
                    </div>

                ) : null
            }
        </div>
    )
}

export default observer(Quiz);