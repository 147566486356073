import React from 'react';
import './modal.sass';
import Oclock from "../../assets/img/Oclock.png";
import {MdClose} from 'react-icons/md';

const Modal = ({closeModal, authorization}) => {

    return (
        <div className="modal">
            <MdClose className="closeIcon" onClick={closeModal} size={24}/>
            <div className='modalContent'>
                <p>Время прохождения и количество правильных ответов влияют на результат</p>
                <img src={Oclock} alt="Изображение часов"/>
            </div>
            <button className="btnBlue" onClick={authorization}>Начать</button>
        </div>
    );
};

export default Modal;