import Menu from './components/menu/Menu.jsx';
import Quiz from './components/quiz/Quiz.jsx';
import Leaders from './components/leaders/Leaders.jsx';
import Layout from './components/layout/Layout.jsx';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Results from "./components/result/Results";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout><Menu/></Layout>}/>
          <Route path="/quiz" element={<Layout><Quiz/></Layout>}/>
          <Route path="/leaders" element={<Layout><Leaders/></Layout>}/>
          <Route path="/result" element={<Layout> <Results/> </Layout>}/>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
